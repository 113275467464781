var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.theme.css.bookWrapper,
      on: {
        mouseover: function ($event) {
          $event.stopPropagation()
          return _vm.delayTip.apply(null, arguments)
        },
        mouseout: function ($event) {
          $event.stopPropagation()
          return _vm.hideTip.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { class: _vm.theme.css.bookLink },
        [
          _c("portal", { attrs: { to: _vm.overlayId } }, [
            _vm.tipVisible && (_vm.book.name || _vm.book.descr)
              ? _c(
                  "div",
                  {
                    ref: "tip",
                    class: _vm.theme.css.tipOverlay,
                    style: _vm.overlayPosition,
                    attrs: { "aria-hidden": "true" },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.theme.css.tip,
                        style: _vm.tipPosition,
                        on: {
                          mouseover: function ($event) {
                            $event.stopPropagation()
                            return _vm.delayTip.apply(null, arguments)
                          },
                          mouseout: function ($event) {
                            $event.stopPropagation()
                            return _vm.hideTip.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: _vm.theme.css.tipLayer1,
                            attrs: { role: "tooltip" },
                          },
                          [
                            _c(
                              "Scroller",
                              {
                                class: _vm.theme.css.tipLayer2,
                                style: _vm.tipSizeLimit,
                                attrs: {
                                  swicher: _vm.tipExpanded,
                                  enabled: _vm.tipExpanded,
                                },
                              },
                              [
                                _c("h3", { ref: "tipHeader" }, [
                                  _vm._v(_vm._s(_vm.book.name)),
                                ]),
                                _vm._v(" "),
                                _c("p", { ref: "tipText" }, [
                                  _vm._v(_vm._s(_vm.book.descr)),
                                ]),
                                _vm._v(" "),
                                _vm.headerLines + _vm.textLines > 6 &&
                                !_vm.tipExpanded
                                  ? _c(
                                      "span",
                                      {
                                        class:
                                          _vm.headerLines >= 6
                                            ? _vm.theme.css.tipShowMoreHeader
                                            : _vm.theme.css.tipShowMore,
                                      },
                                      [
                                        _vm._v("… "),
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                _vm.tipExpanded = true
                                              },
                                            },
                                          },
                                          [_vm._v("More")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", {
                          class: _vm.theme.css.tipCallout,
                          style: _vm.tipNoseOffsetStyle,
                        }),
                        _vm._v(" "),
                        _c("div", {
                          class: _vm.theme.css.tipCalloutLayer1,
                          style: _vm.tipNoseOffsetStyleL1,
                        }),
                        _vm._v(" "),
                        _c("div", {
                          class: _vm.theme.css.tipCalloutLayer2,
                          style: _vm.tipNoseOffsetStyleL2,
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.readableTitle, attrs: { id: _vm.myId } },
            [
              _c("h3", { ref: "tipHeader" }, [_vm._v(_vm._s(_vm.book.name))]),
              _vm._v(" "),
              _c("p", { ref: "tipText" }, [_vm._v(_vm._s(_vm.book.descr))]),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              ref: "link",
              class: _vm.theme.css.bookLinkInner,
              attrs: {
                href: _vm.book.link,
                tabindex: _vm.tabIndex,
                role: "gridcell",
                target: "_blank",
                "aria-colindex": _vm.colIndex + 1,
                "aria-rowindex": _vm.rowIndex + 1,
              },
              on: {
                focus: _vm.noDelayTip,
                blur: _vm.hideTip,
                keyup: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                    )
                      return null
                    $event.stopPropagation()
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    )
                      return null
                    return _vm.nullEvent.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "right", 39, $event.key, [
                        "Right",
                        "ArrowRight",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    if ("button" in $event && $event.button !== 2) return null
                    return _vm.$emit("kbnav", [0.1, 0])
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "left", 37, $event.key, [
                        "Left",
                        "ArrowLeft",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    if ("button" in $event && $event.button !== 0) return null
                    return _vm.$emit("kbnav", [-0.1, 0])
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "home",
                        undefined,
                        $event.key,
                        undefined
                      )
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    )
                      return null
                    return _vm.$emit("kbnav", [-1, 0])
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "end",
                        undefined,
                        $event.key,
                        undefined
                      )
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    )
                      return null
                    return _vm.$emit("kbnav", [1, 0])
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.$emit("kbnav", [0, -0.1])
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.$emit("kbnav", [0, 0.1])
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "home",
                        undefined,
                        $event.key,
                        undefined
                      )
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    if (!$event.ctrlKey) return null
                    return _vm.$emit("kbnav", [-1, -1])
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "end",
                        undefined,
                        $event.key,
                        undefined
                      )
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    if (!$event.ctrlKey) return null
                    return _vm.$emit("kbnav", [1, 1])
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.stopPropagation()
                    return _vm.simulateClick.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    return _vm.simulateClick.apply(null, arguments)
                  },
                ],
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "right", 39, $event.key, [
                        "Right",
                        "ArrowRight",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    if ("button" in $event && $event.button !== 2) return null
                    return _vm.nullEvent.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "left", 37, $event.key, [
                        "Left",
                        "ArrowLeft",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    if ("button" in $event && $event.button !== 0) return null
                    return _vm.nullEvent.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.nullEvent.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.nullEvent.apply(null, arguments)
                  },
                ],
              },
            },
            [
              _c("div", {
                class: _vm.theme.css.bookShadeTop,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.bookShadeTile,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.bookShadeBottom,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _vm.book.thumburl
                ? _c("img", {
                    ref: "tipTarget",
                    class: _vm.theme.css.bookThumb,
                    attrs: {
                      alt: _vm.book.name,
                      "aria-labelledby": _vm.myId,
                      src: _vm.book.thumburl + "?w=220",
                    },
                    on: {
                      load: function ($event) {
                        return _vm.$emit("thumbnailLoaded", _vm.book)
                      },
                    },
                  })
                : _c("div", {
                    class: _vm.theme.css.bookThumb,
                    attrs: { "aria-hidden": "true" },
                  }),
            ]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.theme.css.bookShade }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }