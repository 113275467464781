import Theme from '../theme';
import BasicLayout from '../BasicLayout';
import BasicItem from '../BasicItem';
import layout from './layout.css';
import shelf from './shelf.css';
import header from './header.css';
import book from './book.css';

export default new Theme({
    layoutComponent: BasicLayout,
    itemComponent: BasicItem,
    css: Object.assign({}, layout, shelf, header, book),
    tipMargin: 16,
    tipWidth: 228,
    tipLineHeight: 16,
    tipTitleHeight: 31,
    tipCalloutLayer1Offset: 3,
    tipCalloutLayer2Offset: 3,
    dropDownWidthFix: 1,
    isLegacy: true,
});
