var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.themeEmbedClass,
        {
          [_vm.theme.css.layoutWrapper]: _vm.loggedIn || !_vm.needAuthorization,
          [_vm.theme.css.layoutWrapperCustomBackground]:
            _vm.seriesModel.DisplaySettings.background.enabled,
        },
        _vm.embedClasses,
        _vm.widthClasses,
      ],
      style: { display: _vm.display },
      attrs: { id: _vm.overlayId },
    },
    [
      _c("div", {
        class: _vm.theme.css.layoutCustomBackground,
        style: _vm.seriesModel.DisplaySettings.background.style,
        attrs: { "aria-hidden": "true", role: "none" },
      }),
      _vm._v(" "),
      _c("link", {
        attrs: {
          href: "https://cld.bz/Content/fonts/open-sans.css",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      _vm.loggedIn || !_vm.needAuthorization
        ? [
            _c(
              "div",
              {
                class: {
                  [_vm.theme.css.layoutWrapperBackgroundLayer]: true,
                  [_vm.theme.css.layoutWrapperBackgroundLayerCustomBackground]:
                    _vm.seriesModel.DisplaySettings.background.enabled,
                },
              },
              [
                _c(
                  _vm.theme.layoutComponent,
                  {
                    ref: "container",
                    tag: "component",
                    attrs: {
                      "is-collapsed": _vm.isCollapsed,
                      fluidHeight: _vm.fluidHeight,
                      theme: _vm.theme,
                      series: _vm.seriesModel,
                      overlayId: _vm.overlayId,
                      search: _vm.search,
                      sort: _vm.sort,
                      "has-explicit-sort": _vm.hasExplicitSort,
                      "books-per-row": _vm.booksPerRow,
                      "total-books": _vm.sortedAndFilteredBooks.length,
                    },
                    on: {
                      "grid-focused": function ($event) {
                        return _vm.kbnav(0, [0, 0])
                      },
                      search: function ($event) {
                        _vm.search = $event
                      },
                      sort: function ($event) {
                        return _vm.doSort($event)
                      },
                      scrolling: _vm.hideAllTips,
                    },
                  },
                  [
                    _vm._l(_vm.booksByRow, function (row, r) {
                      return _c(
                        "div",
                        {
                          key: "row" + r,
                          class: _vm.theme.css.booksRow,
                          attrs: { role: "row", "aria-rowindex": r + 1 },
                        },
                        _vm._l(row, function (book, i) {
                          return _c(_vm.theme.itemComponent, {
                            key: book.id,
                            ref: "bookElements",
                            refInFor: true,
                            tag: "component",
                            attrs: {
                              theme: _vm.theme,
                              overlayId: _vm.overlayId,
                              book: book,
                              tabIndex: i + r * _vm.booksPerRow + 9,
                              rowIndex: r,
                              colIndex: i,
                            },
                            on: {
                              kbnav: function ($event) {
                                return _vm.kbnav(
                                  i + r * _vm.booksPerRow,
                                  $event
                                )
                              },
                              focused: function ($event) {
                                return _vm.$refs.container.scrollTo($event)
                              },
                              thumbnailLoaded: _vm.thumbnailLoaded,
                            },
                          })
                        }),
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.bookPlaceholders, function (i) {
                      return _c("div", {
                        key: "ph" + i,
                        class: _vm.theme.css.bookPlaceholder,
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("portal-target", {
              ref: "overlay",
              attrs: { name: _vm.overlayId, slim: true },
            }),
          ]
        : _c("Login", {
            attrs: {
              "embedding-mode": _vm.seriesModel.embeddingMode,
              loginError: _vm.loginError,
            },
            on: { logon: _vm.logon },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }