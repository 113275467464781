var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "root",
      class: [
        _vm.theme.css.shelfWrapper,
        {
          [_vm.theme.css.headerWrapperCustomBackground]:
            _vm.series.DisplaySettings.background.enabled,
        },
      ],
      style: [
        _vm.series.DisplaySettings.background.style,
        _vm.series.DisplaySettings.containerStyle,
      ],
    },
    [
      _vm.series.DisplaySettings.header.enabled
        ? _c(
            "div",
            {
              class: { [_vm.theme.css.headerWrapper]: true },
              style: [
                _vm.series.DisplaySettings.header.foregroundStyle,
                _vm.series.DisplaySettings.header.backgroundStyle,
              ],
            },
            [
              _c("div", {
                class: _vm.theme.css.headerCustomBackground,
                style: _vm.series.DisplaySettings.header.backgroundStyle,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.headerBackgroundTile,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.headerBackgroundLeft,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.headerBackgroundRight,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.theme.css.headerText,
                    {
                      [_vm.theme.css.headerWithSearchActive]:
                        _vm.searchActive || _vm.search,
                    },
                  ],
                  attrs: { role: "heading" },
                },
                [_vm._v(_vm._s(_vm.series.Name) + "\n        ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.theme.css.headerSort,
                    {
                      [_vm.theme.css.headerWithSearchActive]:
                        _vm.searchActive || _vm.search,
                    },
                  ],
                },
                [
                  _c("SortControl", {
                    attrs: {
                      sort: _vm.sort,
                      "is-collapsed": _vm.isCollapsed,
                      theme: _vm.theme,
                      "has-explicit-sort": _vm.hasExplicitSort,
                      "overlay-id": _vm.overlayId,
                      "display-settings": _vm.series.DisplaySettings,
                    },
                    on: {
                      sort: function ($event) {
                        return _vm.$emit("sort", $event)
                      },
                      "focus-next-element": function ($event) {
                        return _vm.$refs.searchIcon.focus()
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.theme.css.headerSearch,
                    {
                      [_vm.theme.css.headerSearchActive]:
                        _vm.searchActive || _vm.search,
                    },
                  ],
                },
                [
                  _c("input", {
                    ref: "searchInput",
                    class: { [_vm.theme.css.searchHasText]: _vm.search },
                    style: [
                      _vm.series.DisplaySettings.header.foregroundStyle,
                      _vm.series.DisplaySettings.header.foregroundBorderStyle,
                    ],
                    attrs: {
                      tabindex: "7",
                      placeholder: "Search…",
                      title: "Search…",
                      role: "searchbox",
                    },
                    domProps: { value: _vm.search },
                    on: {
                      click: _vm.activateSearch,
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.nullEvent.apply(null, arguments)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.blurSearch.apply(null, arguments)
                      },
                      input: _vm.doSearch,
                      blur: _vm.deactivateSearch,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      ref: "searchIcon",
                      class: [_vm.theme.css.searchIcon],
                      style:
                        _vm.series.DisplaySettings.header.foregroundBorderStyle,
                      attrs: {
                        tabindex: "7",
                        role: "button",
                        "aria-label":
                          _vm.searchActive || _vm.search
                            ? "Clear search"
                            : "Search…",
                      },
                      on: {
                        keyup: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.clearSearch.apply(null, arguments)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            )
                              return null
                            $event.preventDefault()
                            return _vm.clearSearch.apply(null, arguments)
                          },
                        ],
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.clearSearch.apply(null, arguments)
                        },
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.clearSearch.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", {
                        class: [_vm.theme.css.searchIcon1],
                        style:
                          _vm.series.DisplaySettings.header
                            .foregroundBorderStyle,
                      }),
                      _vm._v(" "),
                      _c("span", {
                        class: [_vm.theme.css.searchIcon2],
                        style:
                          _vm.series.DisplaySettings.header
                            .foregroundBorderStyle,
                      }),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Scroller",
        {
          ref: "scroller",
          class: _vm.series.DisplaySettings.header.enabled
            ? _vm.theme.css.booksScrollContainerWithHeader
            : _vm.theme.css.booksScrollContainer,
          attrs: {
            tagname: "div",
            settings: { suppressScrollX: true },
            enabled: !_vm.fluidHeight,
          },
          on: {
            "ps-scroll-y": function ($event) {
              return _vm.$emit("scrolling")
            },
          },
        },
        [
          _c(
            "div",
            {
              class: _vm.theme.css.booksWrapper,
              style: [
                _vm.widthLimit,
                _vm.series.DisplaySettings.header.marginStyle,
              ],
            },
            [
              _c("div", {
                class: _vm.theme.css.booksBackgroundTileA,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.booksBackgroundTopTileA,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.booksBackgroundTileB,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.booksBackgroundTopTileB,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.booksBackgroundLeft,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.booksBackgroundRight,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.booksBackgroundTopLeft,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", {
                class: _vm.theme.css.booksBackgroundTopRight,
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "booksContainer",
                  class: _vm.theme.css.booksContainer,
                  attrs: {
                    role: "grid",
                    "aria-colcount": _vm.booksPerRow,
                    "aria-rowcount": Math.ceil(
                      _vm.totalBooks / _vm.booksPerRow
                    ),
                    tabindex: "8",
                    "aria-label": "Bookshelf",
                  },
                  on: {
                    focus: _vm.focusElement,
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "right", 39, $event.key, [
                          "Right",
                          "ArrowRight",
                        ]) &&
                        _vm._k($event.keyCode, "left", 37, $event.key, [
                          "Left",
                          "ArrowLeft",
                        ]) &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ]) &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ]) &&
                        _vm._k($event.keyCode, "tab", 9, $event.key, "Tab") &&
                        _vm._k(
                          $event.keyCode,
                          "enter",
                          13,
                          $event.key,
                          "Enter"
                        ) &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ])
                      )
                        return null
                      if ("button" in $event && $event.button !== 2) return null
                      if ("button" in $event && $event.button !== 0) return null
                      $event.preventDefault()
                      return _vm.focusElement.apply(null, arguments)
                    },
                  },
                },
                [_vm._t("default")],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: _vm.theme.css.shelfShade,
              style: _vm.widthLimit,
              attrs: { "aria-hidden": "true" },
            },
            [
              _c("div", { class: _vm.theme.css.shelfShadeLeft }),
              _vm._v(" "),
              _c("div", { class: _vm.theme.css.shelfShadeTileA }),
              _vm._v(" "),
              _c("div", { class: _vm.theme.css.shelfShadeTileB }),
              _vm._v(" "),
              _c("div", { class: _vm.theme.css.shelfShadeRight }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }