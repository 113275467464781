export default class {
    constructor (src, override) {
        Object.assign(this, {
            enabled: false,
            imageUrl: null,
            scaleMode: 'fill',
            alignment: [0, 0],
        }, src || {});
        if (override.enabled !== undefined && override.enabled !== '') { this.enabled = Boolean(JSON.parse(override.enabled)); }
        if (override.scaleMode !== undefined && override.scaleMode !== '') { this.scaleMode = override.scaleMode; }
        if (override.imageUrl !== undefined && override.imageUrl !== '') { this.imageUrl = override.imageUrl; }
        if (override.alignment !== undefined && override.alignment !== '') {
            const old = this.alignment;
            try {
                this.alignment = JSON.parse(override.alignment);
                if (!Array.isArray(this.alignment) || this.alignment.length !== 2) { throw new Error(); }
            } catch (e) {
                this.alignment = old;
            }
        }
    }

    get style () {
        if (this.__style) {
            return this.__style;
        }
        let styles = {};
        if (this.enabled) {
            if (this.override) {
                styles = this.override;
            } else {
                styles.backgroundImage = 'url(\'' + this.imageUrl + '\')';
                const alignment = this.alignment || [0.5, 0.5];
                styles.backgroundPosition = `${Math.round(alignment[0] * 100)}% ${Math.round(alignment[1] * 100)}%`;
                const scaleMode = this.scaleMode || 'fill';
                switch (scaleMode) {
                    case 'tile':
                        styles.backgroundRepeat = 'repeat';
                        styles.backgroundSize = 'auto auto';
                        break;
                    case 'fit':
                        styles.backgroundRepeat = 'no-repeat';
                        styles.backgroundSize = 'contain';
                        break;
                    case 'fill':
                    default:
                        styles.backgroundRepeat = 'no-repeat';
                        styles.backgroundSize = 'cover';
                }
            }
        }
        return (this._style = styles);
    }
}
