import fetchJsonApi from '../fetchJsonApi';

class AuthManager {
    preauth () {
        this.__persistentToken = localStorage.getItem('PersistentToken');
        this.__token = null;
        const deferredPromise = new Promise((resolve, reject) => {
            this.__loaded = {
                resolve, reject,
            };
        });
        this.__loaded.promise = deferredPromise;
        this.__readOnly = true;
        this.userInfo = null;
        const token = sessionStorage.getItem('Token');
        if (token) {
            this.validateToken(token);
        } else if (this.__persistentToken) {
            this.exchangeToken(this.__persistentToken);
        } else {
            this.tryCookiedAuth();
        }
    }

    get loaded () {
        return this.__loaded.promise;
    }

    get persistentToken () {
        return this.__persistentToken;
    }

    get token () {
        return this.__token;
    }

    get isAuthorized () {
        return !!this.token;
    }

    validateToken (token) {
        return fetchJsonApi('generic', 'logon-tokens', '', 'POST', {
            includeUserInfo: true,
            token,
        }, { urlEncodedForm: true })
            .then(data => this.validToken(data.AccessToken, data.UserInfo, null), () => {
                if (this.__persistentToken) {
                    this.exchangeToken(this.__persistentToken);
                } else {
                    this.__loaded.resolve();
                }
            }, { fetchOptions: { mode: 'cors' } });
    }

    exchangeToken (persistentToken) {
        return fetchJsonApi('generic', 'logon-tokens', '', 'POST', {
            includeUserInfo: true,
            persistlogin: persistentToken,
        }, { urlEncodedForm: true, fetchOptions: { mode: 'cors' } })
            .then(data => this.validToken(data.AccessToken, data.UserInfo, persistentToken), () => this.__loaded.resolve());
    }

    tryCookiedAuth () {
        return fetchJsonApi('generic', 'logon-tokens', '', 'GET', { includeUserInfo: true }, {
            fetchOptions: {
                credentials: 'include',
                mode: 'cors',
            },
        })
            .then(data => this.validToken(data.AccessToken, data.UserInfo, data.PersistentToken), () => this.__loaded.resolve());
    }

    validToken (token, userInfo, persistentToken) {
        this.userInfo = userInfo;
        this.__readOnly = true;
        sessionStorage.setItem('Token', this.__token = token);
        if (persistentToken) {
            localStorage.setItem('PersistentToken', this.__persistentToken = persistentToken);
        }
        this.__loaded.resolve();
        return true;
    }

    tryAuthenticate (username, password, persist) {
        return fetchJsonApi('generic', 'logon', '/CombinedAuthenticate2', 'POST', {
            claimedIdentity: { Email: username },
            authenticationData: { PlainTextPassword: password, IssuePersistentToken: persist, IncludeUserInfo: true },
        }, { fetchOptions: { mode: 'cors', headers: { 'X-CSRF-Token': window.__flippingbook_csrf__ } } })
            .then(data => new Promise((resolve, reject) => {
                if (this.validToken(data.Token.Value, data.UserInfo, data.PersistentToken)) {
                    resolve();
                } else {
                    reject('NoPermissions');
                }
            }));
    }

    clear () {
        localStorage.removeItem('PersistentToken');
        sessionStorage.removeItem('Token');
        this.__token = null;
        this.__persistentToken = null;
        this.userInfo = null;
    }
}

export default new AuthManager();
