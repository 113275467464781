<template>
    <div :class="theme.css.bookWrapper" @mouseover.stop="delayTip" @mouseout.stop="hideTip">
        <div :class="theme.css.bookLink">
            <portal :to="overlayId">
                <div v-if="tipVisible && (book.name || book.descr)" :class="theme.css.tipOverlay" :style="overlayPosition" ref="tip" aria-hidden="true">
                    <div :class="theme.css.tip" :style="tipPosition"  @mouseover.stop="delayTip" @mouseout.stop="hideTip">
                        <div :class="theme.css.tipLayer1" role="tooltip">
                            <Scroller :class="theme.css.tipLayer2" :style="tipSizeLimit" :swicher="tipExpanded" :enabled="tipExpanded">
                                <h3 ref="tipHeader">{{book.name}}</h3>
                                <p ref="tipText">{{book.descr}}</p>
                                <span v-if="headerLines+textLines>6 && !tipExpanded" :class="headerLines>=6?theme.css.tipShowMoreHeader:theme.css.tipShowMore">&hellip;&nbsp;<a @click.stop.prevent="tipExpanded=true" href="#">More</a></span>
                            </Scroller>
                        </div>
                        <div :class="theme.css.tipCallout" :style="tipNoseOffsetStyle"></div>
                        <div :class="theme.css.tipCalloutLayer1" :style="tipNoseOffsetStyleL1"></div>
                        <div :class="theme.css.tipCalloutLayer2" :style="tipNoseOffsetStyleL2"></div>
                    </div>
                </div>
            </portal>
            <div :class="$style.readableTitle" :id="myId">
                <h3 ref="tipHeader">{{book.name}}</h3>
                <p ref="tipText">{{book.descr}}</p>
            </div>
            <a :href="book.link" :tabindex="tabIndex" :class="theme.css.bookLinkInner" role="gridcell" target="_blank" ref="link" :aria-colindex="colIndex+1" :aria-rowindex="rowIndex+1"
               @focus="noDelayTip"
               @blur="hideTip"
               @keyup.stop.exact.tab="nullEvent"
               @keydown.stop.prevent.right="nullEvent"
               @keydown.stop.prevent.left="nullEvent"
               @keyup.stop.prevent.right="$emit('kbnav',[.1,0])"
               @keyup.stop.prevent.left="$emit('kbnav',[-.1,0])"
               @keyup.stop.prevent.exact.home="$emit('kbnav',[-1,0])"
               @keyup.stop.prevent.exact.end="$emit('kbnav',[1,0])"

               @keydown.stop.prevent.up="nullEvent"
               @keydown.stop.prevent.down="nullEvent"
               @keyup.stop.prevent.up="$emit('kbnav',[0,-.1])"
               @keyup.stop.prevent.down="$emit('kbnav',[0,.1])"

               @keyup.stop.prevent.ctrl.home="$emit('kbnav',[-1,-1])"
               @keyup.stop.prevent.ctrl.end="$emit('kbnav',[1,1])"

               @keyup.stop.enter="simulateClick"
               @keyup.stop.space="simulateClick"
            >
                <div aria-hidden="true" :class="theme.css.bookShadeTop"></div>
                <div aria-hidden="true" :class="theme.css.bookShadeTile"></div>
                <div aria-hidden="true" :class="theme.css.bookShadeBottom"></div>
                <img :alt="book.name" v-if="book.thumburl" ref="tipTarget" :aria-labelledby="myId" @load="$emit('thumbnailLoaded', book)" :src="book.thumburl+'?w=220'" :class="theme.css.bookThumb"/>
                <div v-else :class="theme.css.bookThumb" aria-hidden="true"></div>
            </a>
            <div :class="theme.css.bookShade"></div>
        </div>
    </div>
</template>
<style module>
.readableTitle{display:none;}
</style>
<script>
import Scroller from '../../common/Scrollbar';
import genId from '../../common/genId';
let myNumber = 1;
export default {
    components: { Scroller },
    props: ['theme', 'book', 'overlayId', 'tabIndex', 'colIndex', 'rowIndex'],
    data () {
        myNumber += 1;
        return {
            tipVisible: false,
            mouseIn: false,
            tipExpanded: this.theme.isLegacy,
            clientRect: { top: 0, left: 0 },
            headerLines: 0,
            headerLineHeight: this.theme.tipTitleHeight,
            textLines: 0,
            textLineHeight: this.theme.tipLineHeight,
            myId: genId('book-' + (myNumber)),
        };
    },
    watch: {
        tipVisible (val) {
            this.updateTipPosition(val);
        },
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        nullEvent () {},
        simulateClick () {
            if (this.$refs.link) { this.$refs.link.click(); }
        },
        setFocus () {
            if (this.$refs.link) { this.$refs.link.focus(); }
        },
        /* computeLineHeight (el) {
            const mel = document.createElement(el.nodeName);
            mel.setAttribute('style', 'margin:0; padding:0; font-family:' + (el.style.fontFamily || 'inherit') + '; font-size:' + (el.style.fontSize || 'inherit'));
            mel.innerHTML = 'A';
            el.parentNode.appendChild(mel);
            const rv = mel.clientHeight;
            mel.parentNode.removeChild(mel);
            return rv;
        }, */
        delayTip () {
            this.mouseIn = true;
            setTimeout(() => {
                if (this.mouseIn) {
                    if (!this.tipVisible) { this.tipExpanded = this.theme.isLegacy; }
                    this.tipVisible = true;
                    this.$nextTick(() => {
                        if (this.$refs.tipHeader) {
                            this.headerLines = Math.round(this.$refs.tipHeader.scrollHeight / this.headerLineHeight);
                        }
                        if (this.$refs.tipText) {
                            this.textLines = Math.round(this.$refs.tipText.scrollHeight / this.textLineHeight);
                        }
                    });
                }
            }, 666);
        },
        noDelayTip () {
            this.mouseIn = true;
            if (!this.tipVisible) { this.tipExpanded = this.theme.isLegacy; }
            this.tipVisible = true;
            this.$emit('focused', this);
            this.$nextTick(() => {
                if (this.$refs.tipHeader) {
                    this.headerLines = Math.round(this.$refs.tipHeader.scrollHeight / this.headerLineHeight);
                }
                if (this.$refs.tipText) {
                    this.textLines = Math.round(this.$refs.tipText.scrollHeight / this.textLineHeight);
                }
            });
        },
        hideTip () {
            this.mouseIn = false;
            setTimeout(() => {
                if (!this.mouseIn) { this.tipVisible = false; }
            }, 300);
        },
        hideTipNow () {
            this.mouseIn = false;
            this.tipVisible = false;
        },
        handleScroll () {
            this.updateTipPosition(this.tipVisible);
        },
        updateTipPosition (visible) {
            if (visible && this.$refs.tipTarget) {
                const thisPos = this.$refs.tipTarget.getBoundingClientRect();
                this.clientRect.top = thisPos.top;
                this.clientRect.left = ((thisPos.left + thisPos.right) / 2);
            }
        },
    },
    computed: {
        overlayXCenter () {
            return Math.min(Math.max(this.clientRect.left, this.theme.tipWidth / 2 + 5), document.documentElement.clientWidth - this.theme.tipWidth / 2 - 5);
        },
        overlayPosition () {
            return {
                top: this.clientRect.top + 'px',
                left: this.overlayXCenter + 'px',
            };
        },
        tipNoseOffsetStyle () {
            return {
                marginLeft: (this.clientRect.left - this.overlayXCenter) + 'px',
            };
        },
        tipNoseOffsetStyleL1 () {
            return {
                marginLeft: (this.clientRect.left - this.overlayXCenter + this.theme.tipCalloutLayer1Offset) + 'px',
            };
        },
        tipNoseOffsetStyleL2 () {
            return {
                marginLeft: (this.clientRect.left - this.overlayXCenter + this.theme.tipCalloutLayer2Offset) + 'px',
            };
        },
        tipPosition () {
            return {};
        },
        tipSizeLimit () {
            if (this.tipExpanded) {
                let byLine;
                if (this.headerLines >= 12) { byLine = (this.headerLineHeight * 12 + 3); } else { byLine = (this.headerLineHeight * this.headerLines + this.textLineHeight * (12 - this.headerLines) + 3); }
                const realMax = (this.clientRect.top - this.theme.tipMargin);
                return {
                    maxHeight: Math.min(realMax, byLine) + 'px',
                };
            }
            if (!this.headerLines) { return { maxHeight: (this.headerLineHeight + this.textLineHeight * 5 + 3) + 'px', overflow: 'hidden' }; }
            if (this.headerLines >= 6) { return { maxHeight: (this.headerLineHeight * 6 + 3) + 'px', overflow: 'hidden' }; }
            return { maxHeight: (this.headerLineHeight * this.headerLines + this.textLineHeight * (6 - this.headerLines) + 3) + 'px', overflow: 'hidden' };
        },
    },
};
</script>
