<template>
    <div
        :class="[theme.css.shelfWrapper,{[theme.css.headerWrapperCustomBackground]:series.DisplaySettings.background.enabled}]"
        :style="[series.DisplaySettings.background.style, series.DisplaySettings.containerStyle]" ref="root">
        <div :class="{[theme.css.headerWrapper]:true}"
             :style="[series.DisplaySettings.header.foregroundStyle,series.DisplaySettings.header.backgroundStyle]"
             v-if="series.DisplaySettings.header.enabled">
            <div :class="theme.css.headerCustomBackground" :style="series.DisplaySettings.header.backgroundStyle"
                 aria-hidden="true"></div>
            <div :class="theme.css.headerBackgroundTile" aria-hidden="true"></div>
            <div :class="theme.css.headerBackgroundLeft" aria-hidden="true"></div>
            <div :class="theme.css.headerBackgroundRight" aria-hidden="true"></div>
            <div :class="[theme.css.headerText, {[theme.css.headerWithSearchActive]:searchActive||search}]"
                 role="heading">{{ series.Name }}
            </div>
            <div :class="[theme.css.headerSort, {[theme.css.headerWithSearchActive]:searchActive||search}]">
                <SortControl :sort="sort" :is-collapsed="isCollapsed" :theme="theme" :has-explicit-sort="hasExplicitSort" :overlay-id="overlayId"
                             :display-settings="series.DisplaySettings" @sort="$emit('sort',$event)"
                             @focus-next-element="$refs.searchIcon.focus()"/>
            </div>
            <div :class="[theme.css.headerSearch, {[theme.css.headerSearchActive]:searchActive||search}]">
                <input :value="search" tabindex="7" ref="searchInput" placeholder="Search…" title="Search…"
                       :class="{[theme.css.searchHasText]:search}"
                       :style="[series.DisplaySettings.header.foregroundStyle,series.DisplaySettings.header.foregroundBorderStyle]"
                       role="searchbox"
                       @click="activateSearch"
                       @keyup.enter.prevent="nullEvent"
                       @keydown.enter.prevent="blurSearch"
                       @input="doSearch"
                       @blur="deactivateSearch"/>
                <span :class="[theme.css.searchIcon]" tabindex="7" ref="searchIcon"
                      :style="series.DisplaySettings.header.foregroundBorderStyle" role="button"
                      :aria-label="(searchActive||search)?'Clear search':'Search…'"
                      @keyup.prevent.enter="clearSearch"
                      @keyup.prevent.space="clearSearch"
                      @click.stop.prevent="clearSearch"
                      @touchstart.stop.prevent="clearSearch">
                    <span :class="[theme.css.searchIcon1]"
                          :style="series.DisplaySettings.header.foregroundBorderStyle"></span>
                    <span :class="[theme.css.searchIcon2]"
                          :style="series.DisplaySettings.header.foregroundBorderStyle"></span>
                </span>
            </div>
        </div>
        <Scroller @ps-scroll-y="$emit('scrolling')"
                  :class="series.DisplaySettings.header.enabled?theme.css.booksScrollContainerWithHeader:theme.css.booksScrollContainer"
                  tagname="div" :settings="{suppressScrollX:true}" :enabled="!fluidHeight" ref="scroller">
            <div :style="[widthLimit, series.DisplaySettings.header.marginStyle]" :class="theme.css.booksWrapper">
                <div aria-hidden="true" :class="theme.css.booksBackgroundTileA"></div>
                <div aria-hidden="true" :class="theme.css.booksBackgroundTopTileA"></div>
                <div aria-hidden="true" :class="theme.css.booksBackgroundTileB"></div>
                <div aria-hidden="true" :class="theme.css.booksBackgroundTopTileB"></div>
                <div aria-hidden="true" :class="theme.css.booksBackgroundLeft"></div>
                <div aria-hidden="true" :class="theme.css.booksBackgroundRight"></div>
                <div aria-hidden="true" :class="theme.css.booksBackgroundTopLeft"></div>
                <div aria-hidden="true" :class="theme.css.booksBackgroundTopRight"></div>
                <div :class="theme.css.booksContainer" role="grid" :aria-colcount="booksPerRow"
                     :aria-rowcount="Math.ceil(totalBooks/booksPerRow)" tabindex="8" aria-label="Bookshelf"
                     ref="booksContainer"
                     @focus="focusElement"
                     @keyup.right.left.down.up.tab.enter.space.prevent="focusElement"
                >
                    <slot/>
                </div>
            </div>
            <div :style="widthLimit" :class="theme.css.shelfShade" aria-hidden="true">
                <div :class="theme.css.shelfShadeLeft"></div>
                <div :class="theme.css.shelfShadeTileA"></div>
                <div :class="theme.css.shelfShadeTileB"></div>
                <div :class="theme.css.shelfShadeRight"></div>
            </div>
        </Scroller>
    </div>
</template>

<script>
import Scroller from '../../common/Scrollbar';
import SortControl from './SortControl';

export default {
    components: { Scroller, SortControl },
    props: [
        // css темы
        'theme',
        // данные коллекции
        'series',
        // строка поиска
        'search',
        // сортировка
        'sort',
        // признак наличия сортировки автором
        'hasExplicitSort',
        // автовысота для эмбеда
        'fluidHeight',
        // id элемента-оверлея, куда вставляются всплывающие штуки
        'overlayId',
        // количество книжек на строку
        'booksPerRow',
        // общее количество книжек
        'totalBooks',
        // ширина экрана (на самом деле vieport-а) соответствует мобилам
        'isCollapsed'],
    data () {
        return {
            maxRowLength: this.series.DisplaySettings.maxBooksPerRow || 5,
            searchActive: false,
            searchBlurred: false,
            preventSearchClick: false,
            searchEnabled: false,
        };
    },
    mounted () {
        if (this.series.DisplaySettings.header.foregroundColor) {
            const cssText = `.${this.theme.css.headerSearch} > input::-ms-input-placeholder{color:${this.series.DisplaySettings.header.foregroundColor} !important}
                    .${this.theme.css.headerSearch} > input::-webkit-input-placeholder{color:${this.series.DisplaySettings.header.foregroundColor} !important}
                    .${this.theme.css.headerSearch} > input::placeholder{color:${this.series.DisplaySettings.header.foregroundColor} !important}
                    .${this.theme.css.searchIcon}:after{border-color:${this.series.DisplaySettings.header.foregroundColor}}
                    .${this.theme.css.searchIcon}:before{border-color:${this.series.DisplaySettings.header.foregroundColor}}`;
            const style = document.createElement('style');
            style.setAttribute('type', 'text/css');
            if (this.series.embeddingMode === 'noFrame' || this.series.embeddingMode === 'noFrameFluid') {
                style.innerHTML = cssText;
                this.$el.insertBefore(style, this.$el.firstChild);
            } else {
                style.innerText = cssText;
                document.head.appendChild(style);
            }
        }
        // Включаем поиск через 1сек после монтирования компонента, иначе IE каким-то странным образом его активирует
        setTimeout(() => (this.searchEnabled = true), 1000);
    },
    methods: {
        focusElement () {
            this.$emit('grid-focused');
        },
        nullEvent () {
        },
        doSearch (ev) {
            if (!this.searchEnabled) { return; }
            this.$emit('search', ev.target.value);
            this.searchActive = true;
        },
        scrollTo (item) {
            this.$refs.scroller.scrollTo(item.$el);
        },
        activateSearch () {
            if (this.searchBlurred || !this.searchEnabled) {
                return;
            }
            this.searchActive = true;
            this.$refs.root.focus();
            if (/android/i.test(navigator.userAgent)) {
                // nextTick не всегда работает, так как в chrome android клавиатура не появится, если прошло <100мс
                setTimeout(() => {
                    this.$refs.searchInput.focus();
                    this.searchBlurred = false;
                }, 100);
            } else {
                this.$nextTick(() => {
                    this.$refs.searchInput.focus();
                    this.searchBlurred = false;
                });
            }
        },
        blurSearch () {
            this.preventSearchClick = true;
            setTimeout(() => (this.preventSearchClick = false), 200);
            this.$refs.booksContainer.focus();
        },
        deactivateSearch () {
            this.searchActive = false;
            this.searchBlurred = true;
            setTimeout(() => (this.searchBlurred = false), 200);
        },
        clearSearch () {
            if (this.preventSearchClick) {
                return;
            }
            this.preventSearchClick = true;
            setTimeout(() => (this.preventSearchClick = false), 200);
            if (this.searchBlurred && !this.search) {
                this.searchBlurred = false;
                return;
            }

            if (this.search || this.searchActive) {
                this.searchActive = false;
                this.$emit('search', '');
                this.$refs.searchInput.blur();
            } else {
                this.activateSearch();
            }
        },

    },
    computed: {
        widthLimit () {
            if (this.maxRowLength === -1) {
                return {};
            }
            return {
                'max-width': (155 * (this.maxRowLength > 3 ? this.maxRowLength : 3) + 100) + 'px',
            };
        },

    },
};
</script>
