import '../common/init/minimal';
import '../common/init/serviceDiscovery';
import '../common/init/portal';
import Vue from 'vue';
import css from './index.css';
import comp from './index.vue';
import scrollbarWidth from './scrollbarWidth';
window.__cldBzApi = window.__cldBzApi || {};
document.addEventListener('DOMContentLoaded', () => {
    if (window.__cldBzApi.scrollbarWidth === null || window.__cldBzApi.scrollbarWidth === undefined) { window.__cldBzApi.scrollbarWidth = scrollbarWidth(); }
    const rootWrapper = document.createElement('div');
    rootWrapper.className = css.rootWrapper;
    document.body.appendChild(rootWrapper);
    window.app = new Vue({
        el: rootWrapper,
        render: h => h(comp),
    });
});
