var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.$style["embed_" + _vm.embeddingMode],
        _vm.$style.loginWrapper,
      ],
    },
    [
      _c(
        "form",
        {
          class: _vm.$style.loginForm,
          attrs: {
            method: "post",
            action: "",
            role: "form",
            "aria-labelledby": _vm.genId("login-label"),
          },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.tryLogin.apply(null, arguments)
            },
          },
        },
        [
          _c("h1", { attrs: { id: _vm.genId("login-label") } }, [
            _vm._v("Credentials required"),
          ]),
          _vm._v(" "),
          _c(
            "h4",
            {
              class: _vm.$style.problemDescription,
              attrs: { role: "presentation" },
            },
            [
              _vm._v(
                "\n            This collection is private. You must have permission to access it."
              ),
              _c("br"),
              _vm._v(
                " If you have permission to view this collection, please log in."
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.loginControls }, [
            _c("div", { class: _vm.$style.loginRow }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                class: [
                  { [_vm.$style.hasError]: _vm.loginError || _vm.localError },
                ],
                attrs: {
                  type: "email",
                  placeholder: "Email",
                  role: "textbox",
                  title: "Email",
                  tabindex: "1",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.localError && !_vm.loading
              ? _c(
                  "div",
                  {
                    class: [_vm.$style.error, _vm.$style.loginRow],
                    attrs: { role: "alert" },
                  },
                  [
                    _vm.localError == "BadLogin"
                      ? [_vm._v("Invalid username or password.")]
                      : _vm.localError == "RateLimit"
                      ? [
                          _vm._v(
                            "Looks like something glitched. Please wait a few seconds and try again."
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm.loginError === "AccessDenied" && !_vm.loading
              ? _c(
                  "div",
                  {
                    class: [_vm.$style.error, _vm.$style.loginRow],
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._v(
                      "\n                You don't have permission to view it.\n            "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { class: _vm.$style.loginRow }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                attrs: {
                  type: "password",
                  placeholder: "Password",
                  role: "textbox",
                  title: "Password",
                  tabindex: "2",
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: _vm.$style.loginButton,
                  attrs: { type: "submit", role: "button", tabindex: "4" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.tryLogin.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Login")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.$style.loginRow }, [
              _c("label", { class: _vm.$style.rememberMe }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rememberMe,
                      expression: "rememberMe",
                    },
                  ],
                  attrs: { type: "checkbox", role: "checkbox", tabindex: "3" },
                  domProps: {
                    checked: Array.isArray(_vm.rememberMe)
                      ? _vm._i(_vm.rememberMe, null) > -1
                      : _vm.rememberMe,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.rememberMe,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.rememberMe = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.rememberMe = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.rememberMe = $$c
                      }
                    },
                  },
                }),
                _vm._v(" Remember me"),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: _vm.$style.forgetPassword,
                  attrs: {
                    href: _vm.restoreUrl,
                    role: "button",
                    tabindex: "5",
                  },
                },
                [_vm._v("Forgot your password?")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }