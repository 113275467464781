import baseCss from './base.css';
import baseBook from './baseBook.css';
import header from './header.css';

export default class {
    constructor (props) {
        this._layoutComponent = props.layoutComponent;
        this._itemComponent = props.itemComponent;
        this._css = Object.assign({}, baseCss, baseBook, header, props.css);
        this._tipMargin = props.tipMargin || 55;
        this._tipWidth = props.tipWidth || 280;
        this._tipTitleHeight = props.tipTitleHeight || 18;
        this._tipLineHeight = props.tipLineHeight || 16;
        this._tipCalloutLayer1Offset = props.tipCalloutLayer1Offset || 0;
        this._tipCalloutLayer2Offset = props.tipCalloutLayer2Offset || 0;
        this._dropDownWidthFix = props.dropDownWidthFix || 0;
        this._noMarginsBreakpoint = props.noMarginsBreakpoint || 1100;
        this._noSidesBreakpoint = props.noSidesBreakpoint || 745;
        this._mobileHeaderBreakpoint = props.mobileHeaderBreakpoint || 675;
        this._isLegacy = !!props.isLegacy;
        this._preProcessSettings = props.preProcessSettings || (x => x);
    }

    preProcessSettings (settings) {
        return this._preProcessSettings(settings);
    }

    get noMarginsBreakpoint () {
        return this._noMarginsBreakpoint;
    }

    get noSidesBreakpoint () {
        return this._noSidesBreakpoint;
    }

    get mobileHeaderBreakpoint () {
        return this._mobileHeaderBreakpoint;
    }

    get layoutComponent () {
        return this._layoutComponent;
    }

    get isLegacy () {
        return this._isLegacy;
    }

    get itemComponent () {
        return this._itemComponent;
    }

    get css () {
        return this._css;
    }

    get tipMargin () {
        return this._tipMargin;
    }

    get tipWidth () {
        return this._tipWidth;
    }

    get dropDownWidthFix () {
        return this._dropDownWidthFix;
    }

    get tipCalloutLayer1Offset () {
        return this._tipCalloutLayer1Offset;
    }

    get tipCalloutLayer2Offset () {
        return this._tipCalloutLayer2Offset;
    }

    get tipTitleHeight () {
        return this._tipTitleHeight;
    }

    get tipLineHeight () {
        return this._tipLineHeight;
    }
}
