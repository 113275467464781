import css from '../../css/perfect-scrollbar.css';
const cls = {
    main: css.ps,
    rtl: css.psRtl,
    element: {
        thumb: x => css[`psThumb_${x}`],
        rail: x => css[`psRail_${x}`],
        consuming: css.psChildConsume,
    },
    state: {
        focus: css.psFocus,
        clicking: css.psClicking,
        active: x => css[`psActive_${x}`],
        scrolling: x => css[`psScrolling_${x}`],
    },
};

export default cls;

/*
 * Helper methods
 */
const scrollingClassTimeout = { x: null, y: null };

export function addScrollingClass (i, x) {
    const classList = i.element.classList;
    const className = cls.state.scrolling(x);

    if (classList.contains(className)) {
        clearTimeout(scrollingClassTimeout[x]);
    } else {
        classList.add(className);
    }
}

export function removeScrollingClass (i, x) {
    scrollingClassTimeout[x] = setTimeout(
        () => i.isAlive && i.element.classList.remove(cls.state.scrolling(x)),
        i.settings.scrollingThreshold,
    );
}

export function setScrollingClassInstantly (i, x) {
    addScrollingClass(i, x);
    removeScrollingClass(i, x);
}
