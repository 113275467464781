<template>
    <div :class="[themeEmbedClass, {[theme.css.layoutWrapper]:loggedIn || !needAuthorization, [theme.css.layoutWrapperCustomBackground]:seriesModel.DisplaySettings.background.enabled}, embedClasses, widthClasses]" :id="overlayId" :style="{display: display}">
        <div :class="theme.css.layoutCustomBackground" :style="seriesModel.DisplaySettings.background.style" aria-hidden="true" role="none"></div>
        <link href="https://cld.bz/Content/fonts/open-sans.css" rel="stylesheet">
        <template v-if="loggedIn || !needAuthorization">
            <div :class="{[theme.css.layoutWrapperBackgroundLayer]:true,[theme.css.layoutWrapperBackgroundLayerCustomBackground]:seriesModel.DisplaySettings.background.enabled}">
            <component :is="theme.layoutComponent" ref="container"
                       :is-collapsed="isCollapsed" :fluidHeight="fluidHeight" :theme="theme" :series="seriesModel" :overlayId="overlayId" :search="search" :sort="sort" :has-explicit-sort="hasExplicitSort" :books-per-row="booksPerRow" :total-books="sortedAndFilteredBooks.length"
                       @grid-focused="kbnav(0,[0,0])"
                       @search="search=$event"
                       @sort="doSort($event)"
                       @scrolling="hideAllTips">

                <div role="row" v-for="(row,r) in booksByRow" :class="theme.css.booksRow" :key="'row'+r" :aria-rowindex="r+1">
                    <component :is="theme.itemComponent" v-for="(book,i) in row" :key="book.id" :theme="theme" :overlayId="overlayId" ref="bookElements" :book="book" :tabIndex="i+r*booksPerRow+9" :rowIndex="r" :colIndex="i"
                               @kbnav="kbnav(i+r*booksPerRow,$event)"
                               @focused="$refs.container.scrollTo($event)"
                               @thumbnailLoaded="thumbnailLoaded"
                    />
                </div>
                <div :class="theme.css.bookPlaceholder" v-for="i in bookPlaceholders" :key="'ph'+i"></div>
            </component>
            </div>
            <portal-target :name="overlayId" :slim="true" ref="overlay" />
        </template>
        <Login v-else @logon="logon" :embedding-mode="seriesModel.embeddingMode" :loginError="loginError" />
    </div>
</template>
<script>
import themes from './themes';
import Login from '../common/Login';
import fetchJson from '../common/fetchJson';
import DisplaySettings from './DisplaySettings';
import ResizeObserver from 'resize-observer-polyfill';

function compareString (a_, b_) {
    const a = (a_ || '').toLowerCase().trim();
    const b = (b_ || '').toLowerCase().trim();
    if (a < b) { return -1; }
    if (a > b) { return 1; }
    return 0;
}
export default {
    components: { Login },
    props: ['initialConfig', 'host'],
    data () {
        const config = this.initialConfig || window.CldBzConfig || {};
        let themeName = config.SeriesModel.Style;
        const themeOverride = (config.DisplaySettingsOverride || {}).theme;
        if (config.SeriesModel.embeddingMode === 'legacy') {
            themeName = 'legacy';
            if (!config.DisplaySettingsOverride) { config.DisplaySettingsOverride = {}; }
            if (!config.DisplaySettingsOverride.header) { config.DisplaySettingsOverride.header = {}; }
            config.DisplaySettingsOverride.header.margin = 0;
        }
        let theme = themes[themeOverride] || themes[themeName];
        if (!theme) {
            theme = themes.wood;
        }

        const rv = {
            seriesModel: config.SeriesModel,
            theme,
            books: config.SeriesModel.Books.map(x => ({ id: x, isStub: true })),
            search: '',
            sort: 'dateDesc',
            loggedIn: false,
            loginError: null,
            hasExplicitSort: false,
            pageserver: config.Pageserver,
            widthClasses: [],
            embedClasses: [],
            fluidHeight: false,
            booksPerRow: -1,
            isCollapsed: false,
            themeEmbedClass: theme.css['embed_' + config.SeriesModel.embeddingMode] || '',
            overlayId: '__cldBz__series__embed__' + (window.__cldBzApi.overlayId = (window.__cldBzApi.overlayId || 0) + 1),
            display: 'none',
        };
        rv.seriesModel.DisplaySettings = theme.preProcessSettings(new DisplaySettings(rv.seriesModel.DisplaySettings, config.DisplaySettingsOverride || {}));

        rv.hasExplicitSort = (rv.sort = rv.seriesModel.DisplaySettings.sort) === 'explicit';
        if (config.SeriesModel.embeddingMode === 'legacy' || config.SeriesModel.embeddingMode === 'normal') { rv.seriesModel.DisplaySettings.maxBooksPerRow = -1; }
        return rv;
    },
    created () {
        this.heightChanged(this.host);
        if (!this.needAuthorization) { this.loadRealBooks(); }
    },
    mounted () {
        this.display = 'block';
        this._resizeObserver = new ResizeObserver(el => el[0].target === this.$el ? this.widthChanged() : this.heightChanged(el[0].target));
        this._resizeObserver.observe(this.$el);
        this.widthChanged();
        const p = this.host;
        if (p) {
            this._resizeObserver.observe(p);
            this.heightChanged(p);
        }
    },
    beforeDestroy () {
        if (this._resizeObserver) { this._resizeObserver.disconnect(); }
    },
    methods: {
        doSort (dir) {
            this.sort = dir;
        },
        hideAllTips () {
            if (this.$refs.overlay.$el.childElementCount > 0) {
                for (let i = 0; i < this.$refs.bookElements.length; i++) { this.$refs.bookElements[i].hideTipNow(); }
            }
        },
        thumbnailLoaded (item) {
            item.thumbnailLoaded = true;
            const books = this.sortedAndFilteredBooks;
            for (let i = 0; i < books.length; i++) {
                if (!books[i].thumbnailLoaded) {
                    return;
                }
            }
            if (this.host) { this.host.dispatchEvent(new CustomEvent('thumbnails-loaded')); }
        },
        kbnav (item, direction) {
            let curRow = Math.floor(item / this.booksPerRow);
            let curCol = item % this.booksPerRow;
            switch (direction[0]) {
                case -1: curCol = 0; break;
                case -0.1: curCol = curCol > 0 ? curCol - 1 : 0; break;
                case 0.1: curCol = curCol < this.booksPerRow - 1 ? curCol + 1 : curCol; break;
                case 1: curCol = this.booksPerRow - 1; break;
            }
            const maxRow = Math.ceil(this.sortedAndFilteredBooks.length / this.booksPerRow);
            switch (direction[1]) {
                case -1: curRow = 0; break;
                case -0.1: curRow = curRow > 0 ? curRow - 1 : 0; break;
                case 0.1: curRow = curRow < maxRow - 1 ? curRow + 1 : curRow; break;
                case 1: curRow = maxRow - 1; break;
            }
            const linear = Math.min(this.sortedAndFilteredBooks.length - 1, curRow * this.booksPerRow + curCol);
            for (let i = 0; i < this.$refs.bookElements.length; i++) {
                if (this.$refs.bookElements[i].book.__index === linear) {
                    this.$refs.bookElements[i].setFocus();
                    break;
                }
            }
        },
        heightChanged (el) {
            if (!el) { return; }
            const h = (el && el.style.height) || 'auto';
            const cl = [];
            this.fluidHeight = el && (h === 'auto' || !h);
            cl.push(this.fluidHeight ? this.theme.css.noFrameFluid : this.theme.css.noFrame);

            if (this.theme.css[this.seriesModel.embeddingMode]) { cl.push(this.theme.css[this.seriesModel.embeddingMode]); }
            this.embedClasses = cl;
        },
        widthChanged () {
            const w = this.$el.clientWidth;
            const cl = [];
            cl.push(w > this.theme.noMarginsBreakpoint ? this.theme.css.hasMargins : this.theme.css.hasNoMargins);
            cl.push(w > this.theme.noSidesBreakpoint ? this.theme.css.hasShelfSides : this.theme.css.hasNoShelfSides);
            cl.push(w > this.theme.mobileHeaderBreakpoint ? this.theme.css.hasDesktopHeader : this.theme.css.hasMobileHeader);
            this.isCollapsed = w <= this.theme.mobileHeaderBreakpoint;
            this.widthClasses = cl;
            const marginCorrection = w > this.theme.noSidesBreakpoint ? 100 : 4; // Когда-нибудь эти магические числа уедут в константы/настройки темы.
            let max = this.seriesModel.DisplaySettings.maxBooksPerRow || 5;
            if (max === -1) { max = 999999999; }
            this.booksPerRow = Math.min(max, Math.floor((w - marginCorrection) / 155));
        },
        logon (ev) {
            this.loadRealBooks(ev);
        },
        loadRealBooks (token) {
            fetchJson('https://' + this.pageserver + '/series/' + this.seriesModel.Id, 'POST', { accessToken: token }, { urlEncodedForm: true })
                .then((json) => {
                    if (!json.Success) {
                        if (json.Error === 'AccessDenied') {
                            this.loggedIn = false;
                            this.loginError = json.Error;
                            return;
                        }
                        throw new Error('Bad JSON response');
                    }
                    this.loggedIn = true;
                    this.books = json.Books.map((x, i) => { x.ExplicitIndex = i; return x; });
                    if (this.host) { this.host.dispatchEvent(new CustomEvent('metadata-loaded')); }
                }).then(null, (err) => {
                    if (this.host) { this.host.dispatchEvent(new CustomEvent('error', { error: err })); }
                    console.log('Unable to load books', err);
                });
        },
    },
    computed: {
        bookPlaceholders () {
            if (!(this.seriesModel.embeddingMode === 'noFrame' || this.seriesModel.embeddingMode === 'noFrameFluid') || !this.fluidHeight) { return 0; }
            return Math.ceil((this.books.length) / this.booksPerRow) - Math.ceil((this.sortedAndFilteredBooks.length) / this.booksPerRow);
        },
        needAuthorization () {
            return this.seriesModel.Sharing === 'Subscribed' || this.seriesModel.Sharing === 1;
        },
        booksByRow () {
            const books = this.sortedAndFilteredBooks;
            const rv = [];
            if (this.booksPerRow <= 0) { return []; }
            for (let i = 0; i < books.length; i += this.booksPerRow) {
                rv.push(books.slice(i, this.booksPerRow + i));
            }
            return rv;
        },
        sortedAndFilteredBooks () {
            let rset = this.books;
            const term = (this.search || '').toLowerCase();
            if (this.search) {
                rset = [];
                for (let i = 0; i < this.books.length; i++) {
                    if ((this.books[i].name || '').toLowerCase().indexOf(term) !== -1) { rset.push(this.books[i]); }
                }
            }
            switch (this.sort) {
                case 'name':
                    rset.sort((a, b) => compareString(a.name, b.name));
                    break;
                case 'nameDesc':
                    rset.sort((a, b) => -compareString(a.name, b.name));
                    break;
                case 'date':
                case 'dateAsc':
                    rset.sort((a, b) => a.datems - b.datems);
                    break;
                case 'dateDesc':
                    rset.sort((a, b) => b.datems - a.datems);
                    break;
                case 'explicit':default:
                    rset.sort((a, b) => a.ExplicitIndex - b.ExplicitIndex);
                    break;
            }
            for (let i = 0; i < rset.length; i++) { rset[i].__index = i; }
            return rset;
        },
    },
};
</script>
