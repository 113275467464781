import ImageModel from './ImageModel';
import HeaderModel from './HeaderModel';

export default class {
    constructor (serializedSettings, overrides) {
        const _defaults = {
            defaultSort: 'IssueDateDesc',
            background: null,
            header: null,
            padding: 0,
        };
        Object.assign(this, _defaults);
        try {
            const s = JSON.parse(serializedSettings);
            Object.assign(this, s);
        } catch (e) {
            // ignore
        }
        if (Number(overrides.maxbooksperrow) >= 5 || Number(overrides.maxbooksperrow) === -1) { this.maxBooksPerRow = Number(overrides.maxbooksperrow); }
        this.background = new ImageModel(this.background, overrides.background || {});
        this.header = new HeaderModel(this.header, overrides.header || {});
        if (overrides.padding !== undefined && overrides.padding !== '') { this.padding = overrides.padding; }
    }

    get sort () {
        switch (this.defaultSort) {
            case 'Explicit':
                return 'explicit';
            case 'NameAsc':
                return 'nameAsc';
            case 'NameDesc':
                return 'nameDesc';
            case 'IssueDateAsc':
                return 'dateAsc';
            case 'IssueDateDesc':
            default:
                return 'dateDesc';
        }
    }

    get containerStyle () {
        if (this.padding) {
            return {
                paddingBottom: this.padding,
            };
        }
        return {};
    }
}
