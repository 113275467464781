export default function (uri_, method, data, options_) {
    const options = Object.assign({
        urlEncodedForm: false,
        rejectSoftErrors: 'bubbleFirst',
    }, options_ || {});
    const init = Object.assign({
        method,
        headers: {},
    }, options.fetchOptions || {});
    let uri = uri_;
    if (method === 'GET') {
        uri += (uri.indexOf('?') !== -1 ? '&' : '?');
        const pairs = [];
        for (const k in data) { pairs.push(encodeURIComponent(k) + '=' + encodeURIComponent(data[k])); }
        uri += pairs.join('&');
    } else {
        if (options.urlEncodedForm) {
            init.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            const pairs = [];
            for (const k in data) { pairs.push(encodeURIComponent(k) + '=' + encodeURIComponent(data[k])); }
            init.body = pairs.join('&');
        } else {
            init.headers['Content-Type'] = 'application/json';
            init.body = JSON.stringify(data);
        }
    }
    let p = fetch(uri, init).then(response => response.json()).then(data => (data && data.d) ? data.d : data);
    if (options.rejectSoftErrors && options.rejectSoftErrors !== 'no' && options.rejectSoftErrors !== 'false' && options.rejectSoftErrors !== 'off') {
        p = p.then(data => new Promise((resolve, reject) => {
            if (data && data.Success) {
                resolve(data);
            } else {
                if (options.rejectSoftErrors === 'bubbleFirst' && data.Errors && data.Errors.length) {
                    reject(data.Errors[0].Message || data.Errors[0].Description || data.Errors[0].WellKnownError);
                } else if (options.rejectSoftErrors === 'bubble' && data.Errors) {
                    reject(data.Errors);
                } else {
                    reject(data);
                }
            }
        }));
    }
    return p;
}
