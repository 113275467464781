import Theme from '../theme';
import BasicLayout from '../BasicLayout';
import BasicItem from '../BasicItem';
import layout from '../wood-skyless/layout.css';
import shelf from '../wood-skyless/shelf.css';
import book from '../wood-skyless/book.css';
import skyCss from './index.css';
import ctile from './collection-tile.jpg';
import cbg from './collection.jpg';

export default new Theme({
    layoutComponent: BasicLayout,
    itemComponent: BasicItem,
    css: Object.assign({}, layout, shelf, book, skyCss),
    preProcessSettings (settings) {
        if (!settings.background.enabled || (settings.background.enabled && !settings.background.imageUrl)) {
            settings.background.enabled = true;
            settings.background.override = {
                background: 'url(\'' + cbg + '\') no-repeat top center, url(\'' + ctile + '\') repeat-x top left',
            };
        }
        return settings;
    },
});
