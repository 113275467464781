import Theme from '../theme';
import BasicLayout from '../BasicLayout';
import BasicItem from '../BasicItem';
import layout from './layout.css';
import shelf from './shelf.css';
import book from './book.css';

export default new Theme({
    layoutComponent: BasicLayout,
    itemComponent: BasicItem,
    css: Object.assign({}, layout, shelf, book),
});
