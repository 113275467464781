<template>
  <section :is="$props.tagname" :class="systemScrollClasses"
           @ps-scroll-y="scrollHandle" @ps-scroll-x="scrollHandle" @ps-scroll-up="scrollHandle" @ps-scroll-down="scrollHandle" @ps-scroll-left="scrollHandle" @ps-scroll-right="scrollHandle" @ps-y-reach-start="scrollHandle"
           @ps-y-reach-end="scrollHandle" @ps-x-reach-start="scrollHandle" @ps-x-reach-end="scrollHandle">
    <slot></slot>
  </section>
</template>
<style module>
.scrollableX{
    overflow-x: auto;
}
.scrollableY{
    overflow-y: auto;
}
.nonScrollable{
    overflow: hidden;
}
</style>
<script>
import PerfectScrollbar from './perfect-scrollbar/src/index';

function nativeScrollOk () {
    return window.__cldBzApi.scrollbarWidth < 1;
}

export default {
    name: 'vue-custom-scrollbar',
    props: {
        settings: {
            default: null,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        tagname: {
            type: String,
            default: 'section',
        },
    },
    data () {
        return {
            ps: null,
            systemScrollClasses: [],
        };
    },

    methods: {
        scrollHandle (evt) {
            this.$emit(evt.type, evt);
        },
        scrollTo (element) {
            this.ps && this.ps.scrollTo(element);
        },
        update () {
            this.ps && this.ps.update();
        },
        __init () {
            if (this.enabled) {
                if (nativeScrollOk()) {
                    this.systemScrollClasses = {
                        [this.$style.scrollableX]: !this.settings.suppressScrollX,
                        [this.$style.scrollableY]: !this.settings.suppressScrollY,
                    };
                } else {
                    this.systemScrollClasses = [];
                    if (!this._ps_inited) {
                        this._ps_inited = true;
                        this.ps = new PerfectScrollbar(this.$el, this.settings);
                    } else {
                        this.ps && this.ps.update();
                    }
                }
            }
        },
        __uninit () {
            if (nativeScrollOk()) {
                this.systemScrollClasses = [this.$style.nonScrollable];
            } else {
                this.systemScrollClasses = [];
                if (this.ps) {
                    this.ps.destroy();
                    this.ps = null;
                    this._ps_inited = false;
                }
            }
        },
    },
    watch: {
        enabled (val) {
            if (val && !this._ps_inited) {
                this.__init();
            }
            if (!val && this._ps_inited) {
                this.__uninit();
            }
        },
        settings: {
            deep: true,
            handler () {
                this.__uninit();
                this.__init();
            },
        },
        $route () {
            this.update();
        },
    },
    mounted () {
    // for support ssr
        if (!this.$isServer) {
            this.__init();
        }
    },
    updated () {
        this.$nextTick(this.update);
    },
    activated () {
        this.__init();
    },
    deactivated () {
        this.__uninit();
    },
    beforeDestroy () {
        this.__uninit();
    },
};
</script>
