<template>
    <div :class="[$style['embed_'+embeddingMode], $style.loginWrapper]">
        <form method="post" action="" :class="$style.loginForm" @submit.stop.prevent="tryLogin" role="form" :aria-labelledby="genId('login-label')">
            <h1 :id="genId('login-label')">Credentials required</h1>
            <h4 :class="$style.problemDescription" role="presentation">
                This collection is private. You must have permission to access it.<br/> If you have permission to view this collection, please log in.</h4>
            <div :class="$style.loginControls">
                <div :class="$style.loginRow">
                    <input type="email" :class="[{[$style.hasError]: loginError||localError}]" v-model="email" placeholder="Email" role="textbox" title="Email" tabindex="1" />
                </div>
                <div v-if="localError && !loading" :class="[$style.error, $style.loginRow]" role="alert">
                    <template v-if="localError=='BadLogin'">Invalid username or password.</template>
                    <template v-else-if="localError=='RateLimit'">Looks like something glitched. Please wait a few seconds and try again.</template>
                </div>
                <div v-else-if="loginError==='AccessDenied' && !loading" :class="[$style.error, $style.loginRow]" role="alert">
                    You don't have permission to view it.
                </div>
                <div :class="$style.loginRow">
                    <input type="password" v-model="password" placeholder="Password" role="textbox" title="Password" tabindex="2"/> <button type="submit" @click.stop.prevent="tryLogin" :class="$style.loginButton" role="button" tabindex="4">Login</button>
                </div>

                <div :class="$style.loginRow">
                    <label :class="$style.rememberMe"><input type="checkbox" v-model="rememberMe" role="checkbox" tabindex="3"/> Remember me</label> <a :href="restoreUrl" :class="$style.forgetPassword" role="button" tabindex="5">Forgot your password?</a>
                </div>
            </div>
        </form>
    </div>
</template>
<style module src="./index.css"/>
<script>
import auth from './auth';
import sd from 'fb-utils/serviceDiscovery';
import genId from '../genId';

export default {
    components: {},
    props: ['loginError', 'embeddingMode'],
    data () {
        return {
            siteUrl: '',
            email: '',
            password: '',
            rememberMe: true,
            localError: false,
            loading: false,
        };
    },
    created () {
        sd.instance().GetApi('generic', 'logon-restore-ui').then(u => (this.siteUrl = u));
        auth.preauth();
        auth.loaded.then(() => {
            if (auth.isAuthorized) { this.$emit('logon', auth.token); }
        });
    },
    methods: {
        genId,
        tryLogin () {
            this.localError = null;
            this.loading = true;
            auth.tryAuthenticate(this.email, this.password, this.rememberMe).then(() => {
                this.$emit('logon', auth.token);
                this.loading = false;
            }, (err) => {
                if (err === 'Request rate limit exceeded, try again later') {
                    this.localError = 'RateLimit';
                } else {
                    this.localError = 'BadLogin';
                }
                this.loading = false;
            });
        },
    },
    computed: {
        restoreUrl () {
            return this.siteUrl + '?r=fbcloud' + encodeURIComponent(window.location.pathname);
        },
    },
};
</script>
