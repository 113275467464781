let cachedScrollbarWidth = null;
export default function () {
    if (cachedScrollbarWidth === null) {
        if (typeof document === 'undefined') {
            cachedScrollbarWidth = 0;
            return cachedScrollbarWidth;
        }

        const body = document.body;
        const box = document.createElement('div');

        box.style.position = 'fixed';
        box.style.left = 0;
        box.style.visibility = 'hidden';
        box.style.overflowY = 'scroll';
        body.appendChild(box);

        const width = box.getBoundingClientRect().right;

        body.removeChild(box);

        cachedScrollbarWidth = width;
    }

    return cachedScrollbarWidth;
}
