var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.theme.css.sortControl },
    [
      _c(
        "div",
        {
          ref: "sortControl",
          class: _vm.theme.css.sortControlHandle,
          style: _vm.displaySettings.header.foregroundBorderStyle,
          attrs: {
            tabindex: "1",
            role: "button",
            "aria-haspopup": "listbox",
            "aria-label": "Sort",
            id: _vm.genId("list-sort"),
          },
          on: {
            click: _vm.showDropDown,
            touchstart: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.showDropDown.apply(null, arguments)
            },
            keyup: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.showDropDown.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                )
                  return null
                return _vm.showDropDown.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                return _vm.showDropDown.apply(null, arguments)
              },
            ],
            blur: _vm.maybeHide,
          },
        },
        [
          _c(
            "span",
            {
              class: [
                _vm.theme.css.sortControlEllipsis,
                {
                  [_vm.theme.css.sortControlEllipsisActive]:
                    _vm.dropDownVisible,
                },
              ],
              attrs: { "aria-hidden": "true" },
            },
            _vm._l(3, function (i) {
              return _c("span", {
                key: "e" + i,
                style: _vm.displaySettings.header.foregroundAsBackgroundStyle,
              })
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("portal", { attrs: { to: _vm.overlayId } }, [
        _vm.dropDownVisible
          ? _c(
              "div",
              {
                class: _vm.$style.overlay,
                attrs: {
                  "aria-hidden": !_vm.dropDownVisible,
                  "aria-labelledby": _vm.genId("list-sort"),
                },
                on: { click: _vm.hide },
              },
              [
                _c(
                  "div",
                  {
                    ref: "list",
                    class: _vm.theme.css.sortControlDropDown,
                    style: _vm.dropDownPosition,
                    attrs: { role: "listbox" },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.theme.css.sortControlLabel,
                        attrs: { "aria-hidden": "true" },
                      },
                      [_vm._v("Sort by")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.sorts, function (s, i) {
                      return _c(
                        "div",
                        {
                          key: s.name,
                          ref: "listItem",
                          refInFor: true,
                          class:
                            _vm.sort === s.name
                              ? _vm.theme.css.sortControlItemActive
                              : _vm.theme.css.sortControlItem,
                          attrs: {
                            tabindex: i + 2,
                            role: "option",
                            "aria-selected":
                              _vm.sort == s.name ? "true" : "false",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.applySort(s.name)
                            },
                            blur: function ($event) {
                              return _vm.maybeHide($event, i - 1)
                            },
                            touchstart: function ($event) {
                              $event.preventDefault()
                              return _vm.applySort(s.name)
                            },
                            keyup: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "esc",
                                    27,
                                    $event.key,
                                    ["Esc", "Escape"]
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.hide(-1)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.applySort(s.name)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.applySort(s.name)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "home",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.kbnav($event, -1)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "end",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.kbnav($event, 1)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp",
                                  ])
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.kbnav($event, -0.1)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.kbnav($event, 0.1)
                              },
                            ],
                            keydown: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp",
                                  ])
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.nullevent.apply(null, arguments)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _vm.nullevent.apply(null, arguments)
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "span",
                            { class: _vm.theme.css.sortControlItemContent },
                            [_vm._v(_vm._s(s.value))]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }