var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.$props.tagname,
    {
      tag: "section",
      class: _vm.systemScrollClasses,
      on: {
        "ps-scroll-y": _vm.scrollHandle,
        "ps-scroll-x": _vm.scrollHandle,
        "ps-scroll-up": _vm.scrollHandle,
        "ps-scroll-down": _vm.scrollHandle,
        "ps-scroll-left": _vm.scrollHandle,
        "ps-scroll-right": _vm.scrollHandle,
        "ps-y-reach-start": _vm.scrollHandle,
        "ps-y-reach-end": _vm.scrollHandle,
        "ps-x-reach-start": _vm.scrollHandle,
        "ps-x-reach-end": _vm.scrollHandle,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }