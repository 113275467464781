import { render, staticRenderFns } from "./BasicItem.vue?vue&type=template&id=35f1e1ec"
import script from "./BasicItem.vue?vue&type=script&lang=js"
export * from "./BasicItem.vue?vue&type=script&lang=js"
import style0 from "./BasicItem.vue?vue&type=style&index=0&id=35f1e1ec&module=true&lang=css"

var cssModules = {}
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
        cssModules["$style"] = (style0.locals || style0)
        Object.defineProperty(this, "$style", {
          configurable: true,
          get: function () {
            return cssModules["$style"]
          }
        })
      
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })



        module.hot && module.hot.accept(["./BasicItem.vue?vue&type=style&index=0&id=35f1e1ec&module=true&lang=css"], function () {
          var oldLocals = cssModules["$style"]
          if (oldLocals) {
            var newLocals = require("./BasicItem.vue?vue&type=style&index=0&id=35f1e1ec&module=true&lang=css")
            if (JSON.stringify(newLocals) !== JSON.stringify(oldLocals)) {
              cssModules["$style"] = newLocals
              require("c:\\Jenkins\\workspace\\CldBz_publ.cldbz.website_master\\node_modules\\vue-hot-reload-api\\dist\\index.js").rerender("35f1e1ec")
            }
          }
        })

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("c:\\Jenkins\\workspace\\CldBz_publ.cldbz.website_master\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35f1e1ec')) {
      api.createRecord('35f1e1ec', component.options)
    } else {
      api.reload('35f1e1ec', component.options)
    }
    module.hot.accept("./BasicItem.vue?vue&type=template&id=35f1e1ec", function () {
      api.rerender('35f1e1ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sources/series-display/themes/BasicItem.vue"
export default component.exports