export default class {
    constructor (src, override) {
        Object.assign(this, {
            enabled: true,
            backgroundColor: null,
            foregroundColor: null,
            margin: '25px',
        }, src || {});
        if (override.enabled !== undefined && override.enabled !== '') { this.enabled = Boolean(JSON.parse(override.enabled)); }
        if (override.margin !== undefined && override.margin !== '') { this.margin = override.margin; }
        if (override.backgroundcolor !== undefined && override.backgroundcolor !== '') { this.backgroundColor = override.backgroundcolor; }
        if (override.foregroundcolor !== undefined && override.foregroundcolor !== '') { this.foregroundColor = override.foregroundcolor; }
    }

    get marginStyle () {
        if (this.margin !== null && this.margin !== undefined) {
            return {
                marginTop: this.margin,
            };
        }
        return {};
    }

    get backgroundStyle () {
        if (this._backgroundStyle) { return this._backgroundStyle; }
        const styles = {};
        if (this.backgroundColor) {
            styles.backgroundColor = this.backgroundColor;
        }
        return (this._backgroundStyle = styles);
    }

    get foregroundStyle () {
        if (this._foregroundStyle) { return this._foregroundStyle; }
        const styles = {};
        if (this.foregroundColor) {
            styles.color = this.foregroundColor;
        }
        return (this._foregroundStyle = styles);
    }

    get foregroundAsBackgroundStyle () {
        if (this._foregroundAsBackgroundStyle) { return this._foregroundAsBackgroundStyle; }
        const styles = {};
        if (this.foregroundColor) {
            styles.backgroundColor = this.foregroundColor;
        }
        return (this._foregroundAsBackgroundStyle = styles);
    }

    get foregroundBorderStyle () {
        if (this._foregroundBorderStyle) { return this._foregroundBorderStyle; }
        const styles = {};
        if (this.foregroundColor) {
            styles.borderColor = this.foregroundColor;
        }
        return (this._foregroundBorderStyle = styles);
    }
}
