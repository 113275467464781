<template>
    <div :class="theme.css.sortControl">
        <div tabindex="1" :class="theme.css.sortControlHandle" ref="sortControl" :style="displaySettings.header.foregroundBorderStyle" role="button"
             aria-haspopup="listbox" aria-label="Sort" :id="genId('list-sort')"
             @click="showDropDown"
             @touchstart.stop.prevent="showDropDown"
             @keyup.enter="showDropDown"
             @keyup.space="showDropDown"
             @keyup.down="showDropDown"
             @blur="maybeHide" >
                        <span :class="[theme.css.sortControlEllipsis, {[theme.css.sortControlEllipsisActive]: dropDownVisible}]" aria-hidden="true">
                            <span :style="displaySettings.header.foregroundAsBackgroundStyle" v-for="i in 3" :key="'e'+i"></span>
                        </span>
        </div>
        <portal :to="overlayId">
            <div :class="$style.overlay" v-if="dropDownVisible" @click="hide" :aria-hidden="!dropDownVisible" :aria-labelledby="genId('list-sort')">
                <div :class="theme.css.sortControlDropDown" :style="dropDownPosition" role="listbox" ref="list">
                    <div :class="theme.css.sortControlLabel" aria-hidden="true">Sort by</div>
                    <div v-for="(s,i) in sorts" :key="s.name" ref="listItem" :tabindex="i+2" role="option" :class="sort===s.name?theme.css.sortControlItemActive:theme.css.sortControlItem" :aria-selected="sort==s.name?'true':'false'"
                         @click="applySort(s.name)"
                         @blur="maybeHide($event, i-1)"
                         @touchstart.prevent="applySort(s.name)"
                         @keyup.prevent.esc="hide(-1)"
                         @keyup.prevent.enter="applySort(s.name)"
                         @keyup.prevent.space="applySort(s.name)"
                         @keyup.prevent.home="kbnav($event,-1)"
                         @keyup.prevent.end="kbnav($event,1)"
                         @keyup.prevent.up="kbnav($event,-.1)"
                         @keyup.prevent.down="kbnav($event,.1)"
                         @keydown.prevent.up="nullevent"
                         @keydown.prevent.down="nullevent">
                        <span :class="theme.css.sortControlItemContent">{{s.value}}</span></div>
                </div>
            </div>
        </portal>
    </div>
</template>
<style module>
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow: hidden;
    background: rgba(0, 0, 0, .1);
}
</style>
<script>
import genId from '../../common/genId';

export default {
    data () {
        const sorts = [
            { name: 'name', value: 'Name (A – Z)' },
            { name: 'nameDesc', value: 'Name (Z – A)' },
            { name: 'dateDesc', value: 'New issue first' },
            { name: 'dateAsc', value: 'Old issue first' },
        ];
        if (this.hasExplicitSort) { sorts.splice(0, 0, { name: 'explicit', value: 'Default' }); }
        return {
            dropDownVisible: false,
            dropDownPosition: {
                top: 0 + 'px',
                left: 0 + 'px',
                width: 0 + 'px',
            },
            sorts,
        };
    },
    props: ['sort', 'theme', 'overlayId', 'displaySettings', 'hasExplicitSort', 'isCollapsed'],
    computed: {
        sortDisplay () {
            switch (this.sort) {
                case 'explicit':
                    return 'Default';
                case 'name':
                    return 'Name (A – Z)';
                case 'nameDesc':
                    return 'Name (Z – A)';
                case 'dateAsc':
                    return 'Old issue first';
                case 'dateDesc':
                    return 'New issue first';
            }
            return 'Default';
        },
    },
    methods: {
        genId,
        nullevent () {

        },
        applySort (name) {
            this.$refs.sortControl.focus();
            this.$emit('sort', name);
            this.dropDownVisible = false;
        },
        showDropDown () {
            if (this.dropDownVisible) {
                this.dropDownVisible = false;
                return;
            }
            if (this.isCollapsed) {
                const ctlPos = this.$refs.sortControl.getBoundingClientRect();
                const parentPos = this.$el.parentElement.parentElement.getBoundingClientRect();
                this.dropDownPosition = {
                    top: (ctlPos.bottom + 8) + 'px',
                    left: parentPos.left + 'px',
                    right: 'auto !important',
                    width: parentPos.width + 'px !important',
                    borderRadius: 0,
                };
            } else {
                const ctlPos = this.$refs.sortControl.getBoundingClientRect();
                this.dropDownPosition = {
                    top: (ctlPos.bottom + 8) + 'px',
                    left: (ctlPos.right + this.theme.dropDownWidthFix - 135) + 'px',
                    width: (135 - 2 * this.theme.dropDownWidthFix) + 'px',
                };
            }
            this.dropDownVisible = true;
            // $nextTick не подходит, он исполняется раньше вставляния списка в portal-target.
            setTimeout(() => {
                const el = document.querySelector('#' + this.overlayId + ' .' + this.theme.css.sortControlItemActive.split(' ').join('.'));
                if (el) { el.focus(); }
            }, 50);
        },
        maybeHide (e, direction) {
            let t = e.relatedTarget || document.activeElement;
            const overlay = document.querySelector('#' + this.overlayId);
            while (t && t !== overlay) {
                if (t === this.$refs.sortControl || t === this.$refs.list) {
                    console.log('Found invalid parent', t);
                    return;
                }
                t = t.parentElement;
            }
            this.hide(direction);
        },
        hide (direction) {
            this.dropDownVisible = false;
            if (direction < 0) {
                this.$refs.sortControl.focus();
            } else if (direction > 0) {
                this.$emit('focus-next-element');
            }
        },
        kbnav (ev, dir) {
            let ce = this.$refs.listItem.indexOf(ev.target);
            switch (dir) {
                case -1:ce = 0; break;
                case 1:ce = this.$refs.listItem.length - 1; break;
                case -0.1:ce = Math.max(0, ce - 1); break;
                case 0.1:ce = Math.min(this.$refs.listItem.length - 1, ce + 1); break;
            }
            this.$refs.listItem[ce].focus();
        },
    },
};
</script>
