import wood from './wood';
import woodSkyless from './wood-skyless';
import hanging from './hanging';
import glass from './glass';
import glassLegged from './glass-legged';
import white from './white';
import legacy from './legacy';
export default {
    wood,
    'wood-skyless': woodSkyless,
    hanging,
    glass,
    'glass-legged': glassLegged,
    white,
    legacy,
};
